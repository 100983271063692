import { FC, useContext } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormHandleSubmit } from 'react-hook-form';
import { FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { SmallerThanBreakpoint } from '../../../../util/viewportUtils';
import { EditBillingInfoFormData } from './EditBillingInfo';
import { LanguageContext } from '../../../../contexts/language-context';
import {
  AllInvoicingMethodTypes,
  ConsumerEInvoiceType,
  ExtendedInvoiceMethodType,
  OnlineBroker,
} from '../../../../model';
import ControlTextInput from '../../../../components/common-materialui/form/ControlTextInput';
import ControlDropdownInput from '../../../../components/common-materialui/form/ControlDropdownInput';
import AuthContext from '../../../../contexts/auth-context';

interface Props {
  control: Control<EditBillingInfoFormData, any>;
  getFormValues: UseFormGetValues<EditBillingInfoFormData>;
  handleSubmit: UseFormHandleSubmit<EditBillingInfoFormData>;
  setInvoicingMethod: React.Dispatch<React.SetStateAction<AllInvoicingMethodTypes>>;
  errors: FieldErrors<EditBillingInfoFormData>;
  brokers: OnlineBroker[];
}
const EditBillingInfoCompanyForm: FC<Props> = ({ control, getFormValues, setInvoicingMethod, errors, brokers }) => {
  const { getText } = useContext(LanguageContext);
  const stackWidth = SmallerThanBreakpoint('md') ? '100%' : '50%';
  const invoicingMethod = getFormValues('invoicingMethod');
  const authService = useContext(AuthContext);
  const readOnly = authService.isReadOnlyBillingAddressEnabled();

  const brokerItems = brokers.map((broker) => ({ value: broker.code, text: broker.name }));

  return (
    <Stack sx={{ width: stackWidth }}>
      <ControlTextInput
        control={control}
        name='companyName'
        label='basic-info-customer-name'
        error={errors.companyName}
        validations={['required']}
        disabled={readOnly}
      />
      <ControlTextInput
        control={control}
        name='businessId'
        label='basic-info-company-id'
        error={errors.businessId}
        validations={['required']}
        disabled={readOnly}
      />
      <ControlTextInput
        control={control}
        name='street'
        label='billing-data-street-address'
        error={errors.street}
        validations={['required']}
        disabled={readOnly}
      />
      <ControlTextInput
        control={control}
        name='postalCode'
        label='billing-data-post-code'
        error={errors.postalCode}
        validations={['required', 'post-code']}
        disabled={readOnly}
      />
      <ControlTextInput
        control={control}
        name='postOffice'
        label='billing-data-city'
        error={errors.postOffice}
        validations={['required']}
        disabled={readOnly}
      />
      <Controller
        control={control}
        name='invoicingMethod'
        render={({ field: { onBlur, onChange, value } }) => {
          return (
            <Stack style={{ marginTop: '20px' }}>
              <FormLabel id='billing-data-billing-method-question'>
                {getText('billing-data-billing-method-question')}
              </FormLabel>
              <RadioGroup
                value={value}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                  setInvoicingMethod(e.target.value as AllInvoicingMethodTypes);
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  label={getText('billing-data-billing-method-paper')}
                  value={ExtendedInvoiceMethodType.PaperBillInvoicing}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={getText('billing-data-billing-method-email')}
                  value={ConsumerEInvoiceType.EmailInvoicing}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={getText('billing-data-billing-method-e-invoice')}
                  value={ExtendedInvoiceMethodType.OnlineInvoicing}
                />
              </RadioGroup>
            </Stack>
          );
        }}
      />

      {invoicingMethod === ExtendedInvoiceMethodType.OnlineInvoicing ? (
        <>
          <ControlTextInput
            control={control}
            name='eInvoicingAddress'
            label='e-bill-address'
            error={errors.eInvoicingAddress}
            validations={['required']}
          />
          <ControlDropdownInput
            control={control}
            name='eInvoicingOperator'
            label='broker'
            error={errors.eInvoicingOperator}
            validations={['required']}
            dropdownItems={brokerItems}
          />
          <ControlTextInput
            control={control}
            name='eInvoicingEdiCode'
            label='ovt'
            error={errors.eInvoicingEdiCode}
            validations={['required']}
          />
        </>
      ) : null}
    </Stack>
  );
};

export default EditBillingInfoCompanyForm;
