import { Stack, Container, Divider, Typography, Button } from '@mui/material';
import { FC, useContext } from 'react';
import OverlayDialog, { overlayDialogPadding } from '../../../../components/common-materialui/dialogs/OverlayDialog';
import { LanguageContext } from '../../../../contexts/language-context';
import { SmallerThanBreakpoint } from '../../../../util/viewportUtils';
import { ContactPerson, UpdateEmptyingInfoCompanyReq, UpdateEmptyingInfoPrivateReq } from '../../../../model';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApiContext from '../../../../contexts/api-context';
import ProgressComponent from '../../../../components/common-materialui/ProgressComponent';
import { useForm } from 'react-hook-form';
import EditContactPersonForm from './EditContactPersonForm';

export interface EditEmptyingContactFormData {
  contactFirstName: string; // Pervasive : this should be loaded from PARDYhthlo (first and last name combined)
  contactLastName: string;
  phoneNumber: string;
  email: string;
}

interface Props {
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  contactPerson?: ContactPerson;
  emptyingId?: string;
  isCompany?: boolean;
}

const EditContactPerson: FC<Props> = ({ open, handleClose, contactPerson, emptyingId, isCompany }) => {
  const { getText } = useContext(LanguageContext);
  const { updateEmptyingInfoPrivate, updateEmptyingInfoCompany } = useContext(ApiContext);
  const queryClient = useQueryClient();

  // Pervasive : this should be loaded from PARDYhthlo
  const contactNameArray = contactPerson?.name.split(' ') ?? ['', ''];

  const defaultFormData = {
    contactFirstName: contactNameArray[1] ?? '',
    contactLastName: contactNameArray[0] ?? '',
    phoneNumber: contactPerson?.phoneNumber ?? '',
    email: contactPerson?.email ?? '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditEmptyingContactFormData>({
    defaultValues: defaultFormData,
  });

  const orientation = SmallerThanBreakpoint('md') ? 'column' : 'row';

  function closeAndInvalidateOnSuccess() {
    queryClient.invalidateQueries(['emptying-info', emptyingId]);
    handleClose(false);
  }

  const { mutate: updateContactInformationPrivate, isLoading: updateContractInformationPrivateIsLoading } = useMutation(
    (data: UpdateEmptyingInfoPrivateReq) => {
      // remove empty spaces from phone number
      const modifiedData = { ...data, phoneNumber: data.phoneNumber.replace(/\s/g, '') };
      return updateEmptyingInfoPrivate(emptyingId ?? '', modifiedData);
    },
    {
      onSuccess: () => closeAndInvalidateOnSuccess(),
    }
  );

  const { mutate: updateContactInformationCompany, isLoading: updateContractInformationCompanyIsLoading } = useMutation(
    (data: UpdateEmptyingInfoCompanyReq) => {
      // remove empty spaces from phone number
      const modifiedData = { ...data, phoneNumber: data.phoneNumber.replace(/\s/g, '') };
      return updateEmptyingInfoCompany(emptyingId ?? '', modifiedData);
    },
    {
      onSuccess: () => closeAndInvalidateOnSuccess(),
    }
  );

  function submitUpdate(formData: EditEmptyingContactFormData) {
    const data = {
      contactPerson: formData.contactFirstName + ' ' + formData.contactLastName, // Pervasive : this should be saved PARDYhthlo
      phoneNumber: formData.phoneNumber,
      email: formData.email,
    };

    if (emptyingId) {
      if (isCompany) {
        updateContactInformationCompany(data);
      } else {
        updateContactInformationPrivate(data);
      }
    }
  }

  return (
    <OverlayDialog open={open} onClose={() => handleClose(false)} headerTextKey='emptying-address-edit-contact-person'>
      <Container>
        <Stack direction={orientation} p={overlayDialogPadding} justifyContent={'space-between'} spacing={1}>
          <Typography variant='h6'>{getText('contact-person')}</Typography>

          <EditContactPersonForm control={control} errors={errors} />
        </Stack>
      </Container>

      <Divider />

      <ProgressComponent
        isLoading={updateContractInformationPrivateIsLoading || updateContractInformationCompanyIsLoading}
      />

      <Container>
        <Stack spacing={1} direction='row' justifyContent={'end'}>
          <Button color='secondary' onClick={() => handleClose(false)}>
            {getText('modify-emptying-location-cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={handleSubmit((x) => submitUpdate(x))}
            sx={{ paddingY: 1.75, marginTop: 2 }}
          >
            {getText('modify-emptying-location-save')}
          </Button>
        </Stack>
      </Container>
    </OverlayDialog>
  );
};

export default EditContactPerson;
