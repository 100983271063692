import { Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useMemo } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { LocationInfoFormData } from '../models';
import { UiTexts } from '../../../../model';
import { printDate } from '../../../../util/calendarUtil';
import { EmptyingLocationContext } from '../provider/EmptyingLocationContextProvider';

interface Props {
  customerType: 'private' | 'company';
  locationInfoFormData: LocationInfoFormData;
}

const LocationSummary = ({ customerType, locationInfoFormData }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();

  const { areaOptions, conurbationOptions, buildingUsageTypeOptions, buildingTypeOptions } =
    useContext(EmptyingLocationContext);

  function showData(labelKey: keyof UiTexts, data: string | null) {
    return (
      <Stack direction='column'>
        <Typography variant='body2' color={palette.text.secondary}>
          {getText(labelKey)}
        </Typography>
        <Typography variant='body1' color={palette.text.primary}></Typography>
        {data}
      </Stack>
    );
  }

  const conurbationText = useMemo(() => {
    return conurbationOptions.find((item) => item.value === locationInfoFormData?.conurbation)?.text ?? '';
  }, [locationInfoFormData?.conurbation, conurbationOptions]);

  const areaText = useMemo(() => {
    return areaOptions.find((item) => item.value === locationInfoFormData?.area)?.text ?? '';
  }, [locationInfoFormData?.area, areaOptions]);

  const buildingUsageTypeText = useMemo(() => {
    return buildingUsageTypeOptions.find((item) => item.value === locationInfoFormData?.buildingUsageType)?.text ?? '';
  }, [locationInfoFormData?.buildingUsageType, buildingUsageTypeOptions]);

  const buildingTypeText = useMemo(() => {
    return buildingTypeOptions.find((item) => item.value === locationInfoFormData?.buildingType)?.text ?? '';
  }, [locationInfoFormData?.buildingType, buildingTypeOptions]);

  const locationContactName =
    locationInfoFormData.contactLastName || locationInfoFormData.contactFirstName
      ? `${locationInfoFormData.contactFirstName}  ${locationInfoFormData.contactLastName}`
      : null;

  return (
    <Grid container columns={2} direction='row' rowSpacing={2} sx={{ maxWidth: '540px' }}>
      <Grid item xs={2}>
        <Typography variant='h6'>{getText('add-emptying-location-basic-info')}</Typography>
      </Grid>

      {customerType === 'private' && (
        <Grid item xs={1}>
          {showData('basic-info-name', `${locationInfoFormData.firstName}  ${locationInfoFormData.lastName}`)}
        </Grid>
      )}
      <Grid item xs={1}>
        {showData('add-emptying-location-basic-info-street-address', locationInfoFormData.address)}
      </Grid>

      <Grid item xs={1}>
        {showData('add-emptying-location-basic-info-post-code', locationInfoFormData.postCode)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-basic-info-city', locationInfoFormData.city)}
      </Grid>

      <Grid item xs={2}>
        <Divider />
      </Grid>

      <Grid item xs={2}>
        <Typography variant='h6'>{getText('add-emptying-location-contact')}</Typography>
      </Grid>

      <Grid item xs={1}>
        {showData('basic-info-name', locationContactName)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-contact-phone', locationInfoFormData.contactPhone)}
      </Grid>

      <Grid item xs={1}>
        {showData('add-emptying-location-contact-email', locationInfoFormData.contactEmail)}
      </Grid>

      <Grid item xs={2}>
        <Divider />
      </Grid>

      <Grid item xs={2}>
        <Typography variant='h6'>{getText('add-emptying-location-building-info')}</Typography>
      </Grid>

      <Grid item xs={1}>
        {showData('add-emptying-location-building-info-area', areaText)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-building-info-conurbation', conurbationText)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-building-info-permanent-id', locationInfoFormData.permanentBuildingId)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-building-info-use', buildingUsageTypeText)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-building-info-type', buildingTypeText)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-building-info-apartment-count', locationInfoFormData.apartmentCount)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-building-info-population', locationInfoFormData.population)}
      </Grid>
      <Grid item xs={1}>
        {showData('add-emptying-location-building-info-property-id', locationInfoFormData.propertyId)}
      </Grid>

      <Grid item xs={2}>
        <Divider />
      </Grid>

      <Grid item xs={2}>
        <Typography variant='h6'>{getText('add-emptying-location-contract-info')}</Typography>
      </Grid>

      <Grid item xs={1}>
        {showData('add-emptying-location-contract-info-start-date', printDate(locationInfoFormData.startDate))}
      </Grid>

      <Grid item xs={1}>
        {showData('add-emptying-location-contract-info-reference-number', locationInfoFormData.referenceNumber)}
      </Grid>
    </Grid>
  );
};

export default LocationSummary;
