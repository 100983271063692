import { useState, useContext, useEffect, useCallback } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import ApiContext from '../../contexts/api-context';
import { BillingInfo, EmptyingInfo, OnlineBroker } from '../../model';
import AuthContext from '../../contexts/auth-context';
import { Box, Button, Grid, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../components/common-materialui/header/Header';
import { MyEmptyingLocationsCard, SkeletonCard } from './MyEmptyingLocationsCard';
import SearchBar from '../../components/common-materialui/SearchBar';
import ExceptionHandler, { InfoBoxElements } from '../../components/common-materialui/status-handling/ExceptionHandler';
import ContentLoadingHandler from '../../components/common-materialui/status-handling/ContentLoadingHandler';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import { ReactComponent as InfoImage } from '../../assets/icons/NoEmptyingLocationsBackground.svg';
import AddEmptyingLocation from './add-emptying-location/AddEmptyingLocation';
import OverlayDialog from '../../components/common-materialui/dialogs/OverlayDialog';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const MyEmptyingLocations = (): JSX.Element => {
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [infoElements, setInfoElements] = useState<InfoBoxElements | undefined>(undefined);
  const { getText } = useContext(LanguageContext);
  const api = useContext(ApiContext);
  const authService = useContext(AuthContext);
  const { palette } = useTheme();
  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const smallerThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const headerStackDirection = smallerThanLg ? 'column' : 'row';

  const [filteredEmptyingInfos, setFilteredEmptyingInfos] = useState<EmptyingInfo[] | undefined>(undefined);
  const [showAddLocationDialog, setShowAddLocationDialog] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleCloseAddLocationDialog = useCallback(() => {
    setShowAddLocationDialog(false);
  }, []);

  function filterAndSetEmptyingInfos(data: EmptyingInfo[] | undefined) {
    const filteredInfos = data?.filter((info: EmptyingInfo) =>
      searchMatchString(info).includes(searchPhrase.toUpperCase())
    );
    if (filteredInfos) {
      setFilteredEmptyingInfos(filteredInfos);
    }
  }

  const {
    isLoading,
    data: emptyingInfos,
    error,
  } = useQuery<EmptyingInfo[]>(['emptyingInfos'], () => api.fetchEmptyingInfos(), {
    onSuccess: (data) => {
      filterAndSetEmptyingInfos(data); // fix for inconsistent emptying infos loading
      if (data.length < 1) {
        setInfoElements({
          imageElement: (
            <Box mt={8}>
              <InfoImage />
            </Box>
          ),
          headerKey: 'emptying-locations-no-emptyings-found-header',
          descriptionKey: getNotFoundDescription(),
          extraElements: buttonElement(),
        });
      } else {
        setInfoElements(undefined);
      }
    },
  });

  const { data: billingInfos } = useQuery<BillingInfo[]>(['billingInfos'], () => api.fetchCustomerBillingInfos(), {});
  const { data: onlineBrokers } = useQuery<OnlineBroker[]>(['onlineBrokers'], () => api.fetchOnlineBrokers(), {});

  useEffect(() => {
    filterAndSetEmptyingInfos(emptyingInfos);
  }, [emptyingInfos, searchPhrase]);

  useEffect(() => {
    if (!showAddLocationDialog) {
      queryClient.invalidateQueries(['emptyingInfos']);
    }
  }, [showAddLocationDialog, queryClient]);

  const buttonElement = () => {
    if (authService.isAddEmptyingAccountEnabled()) {
      return (
        <Button
          variant='contained'
          sx={{ color: palette.text.inverse, paddingY: 1.75, marginTop: 2 }}
          onClick={() => setShowAddLocationDialog(true)}
        >
          <Typography variant='button'>{getText('add-emptying-info-link')}</Typography>
        </Button>
      );
    }
  };

  const getNotFoundDescription = () => {
    return authService.isAddEmptyingAccountEnabled() ? 'emptying-locations-no-emptyings-found-description' : undefined;
  };

  const searchMatchString = (info: EmptyingInfo) => {
    return `${info.address.street}, ${info.address?.postalCode} ${info.address.postOffice} ${info.name}`.toUpperCase();
  };

  const billingInfoLink = () => {
    if (authService.isDevelopmentEnabled() && authService.isAddBillingAccountEnabled()) {
      return <Button sx={{ p: 0, color: palette.text.links }}>{getText('add-billing-info-link')}</Button>;
    }
    return <></>;
  };

  const addEmptyingInfoButton = () => {
    if (authService.isAddEmptyingAccountEnabled()) {
      return (
        <Button onClick={() => setShowAddLocationDialog(true)} sx={{ p: 0, color: palette.text.links }}>
          {getText('add-emptying-info-link')}
        </Button>
      );
    }
    return <></>;
  };

  const renderCard = (info: EmptyingInfo) => {
    return <MyEmptyingLocationsCard key={info.id} screenWiderThanPhone={largerThanPhone} emptyingInfo={info} />;
  };

  const renderSkeletonCard = (idx: number) => {
    return <SkeletonCard key={idx} screenWiderThanPhone={largerThanPhone} />;
  };

  const renderSkeleton = () => {
    return (
      <>
        <Skeleton variant='rectangular' height={48} width={'100%'}></Skeleton>
        <Grid container mt={1}>
          {[...Array(3)].map((_x, idx) => renderSkeletonCard(idx))}
        </Grid>
      </>
    );
  };

  const headerContent = () => {
    return (
      <Stack
        spacing={3}
        justifyContent='space-between'
        direction={headerStackDirection}
        marginTop={largerThanPhone ? 1 : 0.75}
        marginBottom={largerThanPhone ? 3 : 2}
      >
        <Typography variant='h2'>
          {getText('emptying-infos')}
          <Typography variant='body1' mt={1}>
            {getText('emptying-infos-desc')}
          </Typography>
        </Typography>
        {infoElements ? null : (
          <Stack spacing={1} direction={headerStackDirection}>
            <Typography mr={1.5} color={palette.text.links}>
              {billingInfoLink()}
            </Typography>
            <Typography color={palette.text.links}>{addEmptyingInfoButton()}</Typography>
          </Stack>
        )}
      </Stack>
    );
  };

  const showSearchBar = searchPhrase !== '' || (filteredEmptyingInfos && filteredEmptyingInfos.length > 9);

  return (
    <>
      <OverlayDialog
        headerTextKey={'add-emptying-location-header'}
        open={showAddLocationDialog}
        onClose={handleCloseAddLocationDialog}
      >
        <AddEmptyingLocation
          onClose={handleCloseAddLocationDialog}
          billingInfos={billingInfos}
          brokers={onlineBrokers}
        />
      </OverlayDialog>
      <Header content={headerContent()} />
      <ProgressComponent isLoading={isLoading} />

      <ExceptionHandler error={error} infoBoxElements={infoElements}>
        <ContentLoadingHandler isLoading={isLoading} skeleton={renderSkeleton()}>
          {showSearchBar ? (
            <SearchBar searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase} marginBottom='1rem' />
          ) : null}

          <Grid container>{filteredEmptyingInfos ? filteredEmptyingInfos.map((info) => renderCard(info)) : null}</Grid>
        </ContentLoadingHandler>
      </ExceptionHandler>
    </>
  );
};

export default MyEmptyingLocations;
