import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import PermitApplicationSkeleton from './PermitApplicationSkeleton';
import PermitAppplicationContainer from './PermitApplicationContainer';
import { LanguageContext } from '../../contexts/language-context';
import { PermitItemInfo, PermitApplicationReply, PermitFormReply } from '../../model';
import ApiContext from '../../contexts/api-context';
import Header from '../../components/common-materialui/header/Header';
import ExceptionHandler from '../../components/common-materialui/status-handling/ExceptionHandler';
import ContentLoadingHandler from '../../components/common-materialui/status-handling/ContentLoadingHandler';
import OverlayDialog from '../../components/common-materialui/dialogs/OverlayDialog';
import NewPermitApplication from './NewPermitApplication';
import { compareDesc } from 'date-fns';

const PermitApplication = (): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const api = useContext(ApiContext);
  const theme = useTheme();
  const [permitAppInfos, setPermitAppInfos] = useState<PermitItemInfo[]>([]);
  const [permitForms, setPermitForms] = useState<PermitFormReply[]>([]);
  const [permitFormsByIdLoading, setPermitFormsByIdLoading] = useState<boolean>(true);
  const [showNewPermitApplicationDialog, setShowNewPermitApplicationDialog] = useState<boolean>(false);

  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const headerStackDirection = largerThanPhone ? 'row' : 'column';

  const { isLoading, data, error, refetch } = useQuery<PermitApplicationReply[] | null>(['permit-application'], () =>
    api.fetchPermitApplications()
  );

  useEffect(() => {
    if (!showNewPermitApplicationDialog) {
      refetch();
    }
  }, [refetch, showNewPermitApplicationDialog]);

  const unitePermitApplicationData = () => {
    const newPermitItemInfos: PermitItemInfo[] | undefined = data?.map((item, index) => {
      const currentForm: PermitFormReply | undefined = permitForms.find((x) => x.id === item.formId);
      const newItem = {
        permitAppId: item.id,
        sentDate: new Date(new Date(item.createdTime).toDateString()),
        expirationDate: currentForm?.expirationDate ?? undefined,
        header: currentForm?.formName ?? '',
        rows: [[getText('permit-application-status'), getPermitApplicationStatusString(item.status)]],
        status: item.status,
      };
      return newItem;
    });

    if (newPermitItemInfos) {
      const sortedPermitItemInfos = newPermitItemInfos.sort((a, b) => compareDesc(a.sentDate, b.sentDate));
      setPermitAppInfos(sortedPermitItemInfos);
    }
  };

  const getPermitForms = () => {
    let promises = [];
    const formIds = data?.map((item) => item.formId);
    const uniques = formIds?.filter(onlyUnique) || [];
    for (var formId of uniques) {
      promises.push(api.fetchPermitFormById(formId));
    }
    Promise.all(promises)
      .then((results) => {
        const newPermitForms: PermitFormReply[] = results.map((res) => {
          return res;
        });
        setPermitForms(newPermitForms);
      })
      .finally(() => setPermitFormsByIdLoading(false));
  };

  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  useEffect(() => {
    getPermitForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    unitePermitApplicationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitForms]);

  const getPermitApplicationStatusString = (status: string) => {
    let statusString = '';

    switch (status) {
      case 'Sent':
        break;
      case 'InProcess':
        statusString = getText('permit-application-status-inprocess');
        break;
      case 'Ready':
        statusString = getText('permit-application-status-ready');
        break;
      default:
        break;
    }
    return statusString;
  };

  const newPermitApplicationButton = () => {
    return (
      <Button
        onClick={() => setShowNewPermitApplicationDialog(true)}
        sx={{ p: 0, color: theme.palette.text.links }}
        disableFocusRipple
      >
        {getText('permit-application-new')}
      </Button>
    );
  };

  const renderHeaderContent = () => {
    return (
      <>
        <Stack
          justifyContent='space-between'
          direction={headerStackDirection}
          marginTop={largerThanPhone ? 1 : 0.75}
          marginBottom={largerThanPhone ? 3 : 2}
        >
          <Stack>
            <Typography
              fontWeight={500}
              fontSize={largerThanPhone ? '40px' : '28px'}
              lineHeight={largerThanPhone ? '42px' : '32px'}
            >
              {getText('permit-application')}
            </Typography>
            <Typography fontWeight={400} fontSize={'16px'} lineHeight={'24px'} marginTop={2}>
              {getText('permit-application-desc')}
            </Typography>
            <Box width={'16rem'}>
              <Typography variant='body1' mt={3}>
                {newPermitApplicationButton()}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <>
      <OverlayDialog
        headerTextKey={'permit-application'}
        open={showNewPermitApplicationDialog}
        onClose={() => setShowNewPermitApplicationDialog(false)}
      >
        <NewPermitApplication onClose={() => setShowNewPermitApplicationDialog(false)} />
      </OverlayDialog>
      <Stack flexDirection={'column'} style={{ marginBottom: '5rem' }}>
        <Header content={renderHeaderContent()} />
        <ExceptionHandler error={error}>
          <ContentLoadingHandler
            isLoading={isLoading || permitFormsByIdLoading}
            skeleton={<PermitApplicationSkeleton />}
          >
            <PermitAppplicationContainer infos={permitAppInfos} />
          </ContentLoadingHandler>
        </ExceptionHandler>
      </Stack>
    </>
  );
};

export default PermitApplication;
